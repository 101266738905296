import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../global/global.service';

@Injectable()
export class ScheduleInitsGuardService {
  get user(): any {
    return this.globalService.user;
  }

  constructor(private router: Router, private globalService: GlobalService) {}

  public canActivate(): boolean {
    this.router.navigate(['/', 'schedule', this.user.school._id]);
    return true;
  }
}
