import { MatMenu } from '@angular/material/menu';
import { ComponentType } from '@angular/cdk/portal';
import { TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogConfig } from '@angular/material/dialog';

/**
 * Get form value.
 * Calculate number of filter, exluding search, skip and limit.
 * @return {number}
 */
export const calcFilterCount = (value: any, exluding?: string[]): number => {
  return Object.keys(value).reduce((acc: number, key: string) => {
    if (
      key === 'skip' ||
      key === 'limit' ||
      key === 'search' ||
      exluding?.includes(key)
    ) {
      return acc;
    }

    if (value[key] && !_.isEmpty(value[key])) {
      acc++;
    }

    return acc;
  }, 0);
};

export enum HeaderConstants {
  TOP_OFFSET = 10,
}

export type ElementType = 'search' | 'tabs' | 'controls';
export type MatButtonType = 'raised' | 'stroked' | 'flat' | undefined;

export type AutocompleteOptions = Array<{ title: string; options: string[] }>;

export interface HeaderMenu {
  template: TemplateRef<any> | ComponentType<any>;
  config: MatDialogConfig;
}

export interface HeaderBreadcrumb {
  label: string;
  url: string;
  query_params?: { [key: string]: string | number };
}

export interface HeaderSearch {
  control: UntypedFormControl;
  placeholder: string;
  hotkey_enable?: boolean;
  width?: number;
  autocomplete?: {
    enabled: boolean;
    value: string;
  };
}

export interface HeaderTab {
  label: string;
  url: string;
  query_params?: { [key: string]: string | number };
  active: boolean;
  icon?: string;
}

export interface HeaderAction {
  type: 'menu' | 'button' | 'mat-menu-button' | 'router_link' | 'href';
  name?: string;
  icon?: string;
  label?: string | number;
  url?: string;
  color?: ThemePalette;
  query_params?: { [key: string]: string | number };
  modificator?: MatButtonType;
  menu?: HeaderMenu;
  tooltip?: string;
  mat_menu?: MatMenu;
  hotkey_enable?: boolean; // for save button (require name 'save');
}

export interface HeaderSelect {
  control: UntypedFormControl;
  options: Array<{ label: string; value: string }>;
  disabled?: boolean;
}

export interface HeaderConfig {
  search?: HeaderSearch;
  tabs?: Array<HeaderTab>;
  actions?: Array<HeaderAction>;
  select?: HeaderSelect;
  breadcrumbs?: Array<HeaderBreadcrumb>;
}
