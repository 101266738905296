import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../global/global.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class GroupAddAndEditGuardService {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private globalService: GlobalService,
  ) {}

  public canActivate(route): boolean {
    if (
      !this.permissionsService.checkPermission({
        name: 'groups_add_edit',
        schools: [route.params.school_id || this.globalService.school._id],
      })
    ) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
