import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { UserService } from '../user/user.service';

@Injectable()
export class UserCheckGuardService {
  constructor(private router: Router, private userService: UserService) {}

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const id =
        route.params.coach_id ||
        route.params.user_id ||
        route.params.profile_id;

      const { success, response } = await this.userService
        .checkUser(id)
        .toPromise();

      if (!success) {
        throw new Error('User Not Found');
      } else if (response._id !== id) {
        this.router.navigate([`/accounts/${response._id}`]);

        return false;
      } else {
        this.userService.current_profile = response;
      }

      return success;
    } catch (error) {
      this.router.navigate(['/error/404']);
      return false;
    }
  }
}
