<div class="page-header-nav">
  <div class="page-header-nav__box">
    <a
      *ngFor="let item of breadcrumbs"
      class="page-header-nav__breadcrumb"
      [routerLink]="item.url"
      [queryParams]="item.query_params"
    >
      {{ item.label }}
    </a>

    <span class="page-header-nav__current">
      {{ page_title }}
    </span>
  </div>

  <div *ngIf="tabs?.length" class="page-header-nav__tabs">
    <a
      *ngFor="let tab of tabs"
      class="page-header-nav__tabs-item"
      [class.page-header-nav__tabs-item--active]="tab.active"
      [routerLink]="tab.url"
      [queryParams]="tab.query_params"
    >
      <ng-container *ngIf="tab.label">
        {{ tab.label }}
      </ng-container>
      <mat-icon *ngIf="tab.icon" class="mat-icon-little">
        {{ tab.icon }}
      </mat-icon>
    </a>
  </div>

  <mat-form-field
    *ngIf="select"
    hideRequiredMarker="true"
    class="mat-form-field-tiny mat-form-field-round mat-form-field-autosize"
    subscriptSizing="dynamic"
  >
    <mat-select
      [formControl]="select.control"
      [disabled]="select.disabled || disabled_elements.get('select')"
      panelClass="mat-select-round mat-select-expand"
    >
      <mat-option *ngFor="let option of select.options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="search || actions?.length" class="page-header-box">
  <mat-form-field
    *ngIf="search"
    class="mat-form-field-tiny"
    subscriptSizing="dynamic"
    [style.width.px]="search.width"
  >
    <mat-icon matPrefix>
      search
    </mat-icon>
    <input
      *ngIf="!search.autocomplete"
      #searchInput
      type="text"
      matInput
      [placeholder]="search.placeholder"
      [formControl]="search.control"
    />

    <ng-container *ngIf="search.autocomplete?.enabled">
      <input
        #searchInput
        type="text"
        matInput
        [placeholder]="search.placeholder"
        [value]="search.autocomplete.value"
        [matAutocomplete]="autocomplete"
        (input)="autocompleteSearch($event.target.value)"
      />

      <mat-autocomplete
        #autocomplete
        (closed)="autocompleteClosed(searchInput.value)"
      >
        <mat-optgroup
          *ngFor="let group of autocomplete_options"
          [label]="'general:placeholder_' + group.title | i18next"
        >
          <mat-option *ngFor="let label of group.options" [value]="label">
            {{ label }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
    </ng-container>
  </mat-form-field>

  <div *ngIf="actions?.length" class="page-header-actions">
    <ng-container *ngFor="let item of actions" [ngSwitch]="item.type">
      <ng-container *ngSwitchCase="'button'" [ngSwitch]="item.modificator">
        <button
          *ngSwitchCase="'flat'"
          class="mat-button-round"
          mat-flat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="click(item.name)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchCase="'stroked'"
          class="mat-button-round"
          mat-stroked-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="click(item.name)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchCase="'raised'"
          class="mat-button-round"
          mat-raised-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="click(item.name)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchDefault
          class="mat-button-round"
          mat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="click(item.name)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
      </ng-container>
      <ng-container
        *ngSwitchCase="'mat-menu-button'"
        [ngSwitch]="item.modificator"
      >
        <button
          *ngSwitchCase="'flat'"
          class="mat-button-round"
          mat-flat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          [matMenuTriggerFor]="item.mat_menu"
        >
          <ng-container *ngTemplateOutlet="itemBody"></ng-container>
        </button>
        <button
          *ngSwitchCase="'stroked'"
          class="mat-button-round"
          mat-stroked-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          [matMenuTriggerFor]="item.mat_menu"
        >
          <ng-container *ngTemplateOutlet="itemBody"></ng-container>
        </button>
        <button
          *ngSwitchCase="'raised'"
          class="mat-button-round"
          mat-raised-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          [matMenuTriggerFor]="item.mat_menu"
        >
          <ng-container *ngTemplateOutlet="itemBody"></ng-container>
        </button>
        <button
          *ngSwitchDefault
          class="mat-button-round"
          mat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          [matMenuTriggerFor]="item.mat_menu"
        >
          <ng-container *ngTemplateOutlet="itemBody"></ng-container>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'menu'" [ngSwitch]="item.modificator">
        <button
          *ngSwitchCase="'flat'"
          #button
          class="mat-button-round"
          mat-flat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="openMenu(button, item.menu)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchCase="'stroked'"
          #button
          class="mat-button-round"
          mat-stroked-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="openMenu(button, item.menu)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchCase="'raised'"
          #button
          class="mat-button-round"
          mat-raised-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="openMenu(button, item.menu)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
        <button
          *ngSwitchDefault
          #button
          class="mat-button-round"
          mat-button
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [disabled]="disabled_elements.get(item.name)"
          (click)="openMenu(button, item.menu)"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'router_link'" [ngSwitch]="item.modificator">
        <a
          *ngSwitchCase="'flat'"
          mat-flat-button
          class="mat-button-round"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [routerLink]="item.url"
          [queryParams]="item.query_params"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchCase="'stroked'"
          mat-stroked-button
          class="mat-button-round"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [routerLink]="item.url"
          [queryParams]="item.query_params"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchCase="'raised'"
          mat-raised-button
          class="mat-button-round"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [routerLink]="item.url"
          [queryParams]="item.query_params"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchDefault
          mat-button
          class="mat-button-round"
          [color]="item.color"
          [matTooltip]="item.tooltip"
          [routerLink]="item.url"
          [queryParams]="item.query_params"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'href'" [ngSwitch]="item.modificator">
        <a
          *ngSwitchCase="'flat'"
          mat-flat-button
          class="mat-button-round"
          target="_blank"
          [color]="item.color"
          [href]="item.url"
          [matTooltip]="item.tooltip"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchCase="'stroked'"
          mat-stroked-button
          class="mat-button-round"
          target="_blank"
          [color]="item.color"
          [href]="item.url"
          [matTooltip]="item.tooltip"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchCase="'raised'"
          mat-raised-button
          class="mat-button-round"
          target="_blank"
          [color]="item.color"
          [href]="item.url"
          [matTooltip]="item.tooltip"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
        <a
          *ngSwitchDefault
          mat-button
          class="mat-button-round"
          target="_blank"
          [color]="item.color"
          [href]="item.url"
          [matTooltip]="item.tooltip"
        >
          <mat-icon *ngIf="item.icon" class="mat-icon-little">
            {{ item.icon }}
          </mat-icon>
          <span *ngIf="item.label">
            {{ item.label }}
          </span>
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>
