import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

@Injectable()
export class DeactivePageGuard {
  public canDeactivate(component: any): boolean {
    return component.canDeactivate().pipe(map((can) => can));
  }
}
