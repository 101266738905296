import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { GlobalService } from '../global/global.service';
import { UserService } from '../user/user.service';

import { PosthogService } from '@services/posthog/posthog.service';

@Injectable()
export class AuthGuardService {
  get user(): any {
    return this.globalService.user;
  }

  constructor(
    private globalService: GlobalService,
    private userService: UserService,
    private router: Router,
    private posthogService: PosthogService,
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      if (this.user) return true;

      const result = await this.userService.getMyData().toPromise();

      if (result.success) {
        const user = result.response.user;

        if (user.roles.find((role) => role === 'user')) {
          this.globalService.user = user;
          this.globalService.permissions = result.response.permissions;
          this.globalService.school = { ...user.school };
          this.globalService.birthdayUsers = result.response.usersBirthday;

          this.posthogService.identifyUser(this.globalService.user);

          this.posthogService.captureEvent('user_logged_in');
        } else {
          throw new Error('Permission denied');
        }
      } else {
        throw new Error('Auth false');
      }

      return true;
    } catch (error) {
      this.globalService.return_after_login = state.url;

      this.router.navigate(['/login']);

      this.posthogService.resetUser();

      return false;
    }
  }
}
