import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

@Pipe({ name: 'workstatus' })
export class WorkStatusPipe implements PipeTransform {
  constructor(private i18NextPipe: I18NextPipe) {}

  public transform(status, state): any {
    if (state === 'paused') {
      return this.i18NextPipe.transform('general:on_pause');
    }

    switch (status) {
      case 'office':
        return this.i18NextPipe.transform('general:work_status_in_office');
      case 'work from home':
        return this.i18NextPipe.transform('general:work_status_in_home');
      case 'business trip':
        return this.i18NextPipe.transform('general:work_status_business_trip');
      case 'sick':
        return this.i18NextPipe.transform('general:work_status_on_sick_leave');
      case 'vacation':
        return this.i18NextPipe.transform('general:work_status_on_holiday');
      case 'day off':
        return this.i18NextPipe.transform('general:work_status_day_of');
      case 'school day off':
        return this.i18NextPipe.transform(
          'general:work_status_day_off_at_home',
        );
      case 'work day off':
        return this.i18NextPipe.transform(
          'general:work_status_day_off_in_school',
        );
      default:
        return '';
    }
  }
}

@Pipe({ name: 'command' })
export class CommandPipe implements PipeTransform {
  constructor(private i18NextPipe: I18NextPipe) {}

  public transform(command): any {
    if (!command) return '';

    switch (command) {
      case 'start':
        return this.i18NextPipe.transform('general:start');
      case 'pause':
        return this.i18NextPipe.transform('general:pause');
      case 'stop':
        return this.i18NextPipe.transform('general:stop');
      case 'penalty':
        return this.i18NextPipe.transform('general:penalty');
      case 'auto':
        return this.i18NextPipe.transform('general:automatic');
      default:
        return '';
    }
  }
}
