import { ExpectedPaymentsAmountGuardService } from './expected-payments-amount.guard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InterviewsGuardService } from '@services/auth/interviews.guard';

import { AccountCallsGuardService } from './account-calls.guard';
import { AccountStudentRolesGuardService } from './account-roles-check.guard';
import { AccountViewGuardService } from './account-view.guard';
import { AuthGuardService } from './auth.guard';
import { BirthdayGuardService } from './birthday.guard';
import { CalendarsConfirmGuardService } from './calendars-confirm.guard';
import { CashboxAccountGuardService } from './cashbox-account.guard';
import { CashboxGuardService } from './cashbox.guard';
import { CertificatesGuardService } from './certificates.guard';
import { ClassesGuardService } from './classes.guard';
import { CoachAddGuardService } from './coach-add.guard';
import { CoachesGuardService } from './coaches.guard';
import { CompaniesGuardService } from './companies.guard';
import { CouponsGuardService } from './coupons.guard';
import { CourseCheckGuardService } from './course-check.guard';
import { CourseEventsGuardService } from './course-events.guard';
import { CoursesAddGuardService } from './courses-add.guard';
import { CoursesGuardService } from './courses.guard';
import { CvsResultsGuardService } from './cvs-results.guard';
import { CvsGuardService } from './cvs.guard';
import { EditAccountsGuardService } from './edit-account.guard';
import { PaymentsGuardService } from './payments.guard';
import { FopsAddGuardService } from './fops-add.guard';
import { GenerateCertificatesGuardService } from './generate-certificates.guard';
import { GroupAddAndEditGuardService } from './group-add-and-edit.guard';
import { GroupAttendanceGuardService } from './group-attendance.guard';
import { GroupChangelogGuardService } from './group-changelog.guard';
import { GroupCheckAndSchoolAccessGuardService } from './group-check-and-school-access.guard';
import { GroupCheckGuardService } from './group-check.guard';
import { GroupLessonsGuardService } from './group-lessons.guard';
import { GroupStatisticsGuardService } from './group-statistics.guard';
import { GroupStudentsGuardService } from './group-students.guard';
import { GroupVideosEditGuardService } from './group-videos-edit.guard';
import { GroupsArchiveGuardService } from './groups-archive.guard';
import { GroupsChatsGuardService } from './groups-chats.guard';
import { GroupsChatsMessagesGuardService } from './groups-chats-messages.guard';
import { BudgetGuardService } from './groups-budget.guard';
import { GroupsRecruitGuardService } from './groups-recruit.guard';
import { GroupsRedirectGuardService } from './groups-redirect.guard';
import { HelpPageGuardService } from './help-page.guard';
import { LoginGuardService } from './login.guard';
import { MailingGuardService } from './mailing.guard';
import { MergeUsersGuardService } from './merge-users.guard';
import { NewCoachGuardService } from './new-caoch-profile.guard';
import { NewCoachCheckAndSchoolAccessGuardService } from './new-coach-check-and-school-access.guard';
import { PasswordChangeGuardService } from './password-change.guard';
import { PollCheckGuardService } from './poll-check.guard';
import { PollCreateGuardService } from './poll-create.guard';
import { PollsCreateTaskGuardService } from './polls-create-task.guard';
import { PollsGuardService } from './polls.guard';
import { ProfileFinesGuardService } from './profile-fines.guard';
import { ProfilePermissionsGuardService } from './profile-permissions.guard';
import { ReservesGuardService } from './reserves.guard';
import { ScheduleInitsGuardService } from './schedule-init.guard';
import { SchoolCheckAndAccessGuardService } from './school-check-and-access.guard';
import { SchoolsAddGuardService } from './schools-add.guard';
import { SearchGuardService } from './search.guard';
import { StatisticsAverageGuardService } from './statistics-average.guard';
import { StatisticsCoursesLoadedGuardService } from './statistics-courses-loaded.guard';
import { StatisticsCoursesDelayGuard } from './statistics-courses-delay.guard';
import { StatisticsCvsGuardService } from './statistics-cvs.guard';
import { StatisticsEmploymentGuardService } from './statistics-employment.guard';
import { StatisticsNpsGuardService } from './statistics-nps.guard';
import { StatisticsRatingCoachesGuardService } from './statistics-rating-coaches.guard';
import { StatisticsSupportGuardService } from './statistics-support.guard';
import { StatisticsGuardService } from './statistics.guard';
import { StudentAddGuardService } from './student-add.guard';
import { StudentNotesGuardService } from './student-notes.guard';
import { SupportGuardService } from './support.guard';
import { UserAddGuardService } from './user-add.guard';
import { UserCheckAndSchoolAccessGuardService } from './user-check-and-school-access.guard';
import { UserCheckGuardService } from './user-check.guard';
import { UserEditGuardService } from './user-edit.guard';
import { UserLoadCheckGuardService } from './user-load-check.guard';
import { UsersListGuardService } from './users-list.guard';
import { VideoGuardService } from './video.guard';
import { VideocourseGuardService } from './videocourse.guard';
import { WorkhistoryGuardService } from './workhistory.guard';
import { ProgrammeGuardService } from './programme.guard';
import { RefundsGuardService } from './refunds.guards';
import { LocalizationsGuardService } from './localizations.guard';
import { LmsUpdatesGuardService } from './lms-updates.guard';
import { CoachInstructionGuardService } from './coach-instruction.guard';
import { WithdrawalGuardService } from './withdrawal.guard';
import { TasksGuardService } from './tasks.guard';
import { CodeTasksGuardService } from './code-tasks.guard';
import { SkillsGuardService } from './skills.guard';
import { MailConfigGuardService } from './mail-config.guard';
import { OdbFopsGuardService } from './odb-fops.guard';
import { KeycallsGuardService } from './keycalls.guard';
import { RecommendationsGuard } from './recommendations.guard';
import { VacanciesGuardService } from './vacancies.guard';
import { StoreGuard } from './store.guard';
import { SprintsGuardService } from './sprints.guard';
import { ParserGuardService } from './parser.guard';
import { QuizGuard } from './quiz.guard';
import { CheckWorkbookGuard } from './check-workbooks.guard';
import { DeactivePageGuard } from './deactive-page.guard';
import { DebtorsGuard } from './detors.guard';
import { PurchasesGuardService } from './purchases.guard';
import { CustomerBalancesGuard } from './customer-balances.guard';
import { WorkbooksGuard } from './workbooks.guard';
import { ConsultationsGuardService } from './consultations.guard';
import { PasswordResetLogsGuardService } from './password-reset-logs.guard';
import { SystemControlGuardService } from './system-control.guard';
import { AchievementsGuardService } from '@services/auth/achievements.guard';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [
    AuthGuardService,
    LoginGuardService,
    UserLoadCheckGuardService,
    SchoolCheckAndAccessGuardService,
    ScheduleInitsGuardService,
    SchoolsAddGuardService,
    ClassesGuardService,
    GroupAddAndEditGuardService,
    GroupCheckGuardService,
    StudentAddGuardService,
    PollCheckGuardService,
    PollCreateGuardService,
    CoachAddGuardService,
    CoursesGuardService,
    CoursesAddGuardService,
    CourseCheckGuardService,
    UserAddGuardService,
    UserCheckGuardService,
    VideoGuardService,
    SearchGuardService,
    CashboxGuardService,
    CourseCheckGuardService,
    VideocourseGuardService,
    MailingGuardService,
    CalendarsConfirmGuardService,
    SupportGuardService,
    UserCheckAndSchoolAccessGuardService,
    GroupCheckAndSchoolAccessGuardService,
    GroupStudentsGuardService,
    GroupVideosEditGuardService,
    PollsGuardService,
    PollsCreateTaskGuardService,
    CoachesGuardService,
    NewCoachGuardService,
    NewCoachCheckAndSchoolAccessGuardService,
    UsersListGuardService,
    FopsAddGuardService,
    WorkhistoryGuardService,
    ProfileFinesGuardService,
    ProfilePermissionsGuardService,
    PasswordChangeGuardService,
    UserEditGuardService,
    StatisticsGuardService,
    StatisticsCoursesLoadedGuardService,
    StatisticsCoursesDelayGuard,
    GenerateCertificatesGuardService,
    CourseEventsGuardService,
    StatisticsNpsGuardService,
    StatisticsEmploymentGuardService,
    StudentNotesGuardService,
    StatisticsAverageGuardService,
    GroupsArchiveGuardService,
    GroupsRecruitGuardService,
    GroupsRedirectGuardService,
    GroupAttendanceGuardService,
    GroupLessonsGuardService,
    GroupStatisticsGuardService,
    GroupChangelogGuardService,
    BirthdayGuardService,
    BudgetGuardService,
    CompaniesGuardService,
    CouponsGuardService,
    CompaniesGuardService,
    CvsGuardService,
    StatisticsCvsGuardService,
    PaymentsGuardService,
    StatisticsRatingCoachesGuardService,
    CvsResultsGuardService,
    AccountStudentRolesGuardService,
    EditAccountsGuardService,
    MergeUsersGuardService,
    AccountViewGuardService,
    HelpPageGuardService,
    AccountCallsGuardService,
    CashboxAccountGuardService,
    StatisticsSupportGuardService,
    CertificatesGuardService,
    InterviewsGuardService,
    ProgrammeGuardService,
    RefundsGuardService,
    LocalizationsGuardService,
    GroupsChatsGuardService,
    GroupsChatsMessagesGuardService,
    ReservesGuardService,
    LmsUpdatesGuardService,
    CoachInstructionGuardService,
    WithdrawalGuardService,
    TasksGuardService,
    CodeTasksGuardService,
    SkillsGuardService,
    MailConfigGuardService,
    OdbFopsGuardService,
    KeycallsGuardService,
    ExpectedPaymentsAmountGuardService,
    RecommendationsGuard,
    VacanciesGuardService,
    StoreGuard,
    SprintsGuardService,
    ParserGuardService,
    QuizGuard,
    CheckWorkbookGuard,
    DeactivePageGuard,
    DebtorsGuard,
    PurchasesGuardService,
    CustomerBalancesGuard,
    WorkbooksGuard,
    ConsultationsGuardService,
    PasswordResetLogsGuardService,
    SystemControlGuardService,
    AchievementsGuardService,
  ],
})
export class GuardsModule {}
