import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class CoachAddGuardService {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      !this.permissionsService.checkPermission({
        name: 'coach_add',
      })
    ) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
