import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from '@env/environment';
import { LANG } from '@localization/localization.constants';
import { HttpService } from '@services/http/http.service';
import {
  I18NextLoadResult,
  I18NEXT_SERVICE,
  ITranslationService,
} from 'angular-i18next';
import { of } from 'rxjs';
import i18Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const LANGUAGES_DETECTOR_OPTIONS = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: 'i18next_lng',
  caches: ['localStorage'],
};

const angularLocaleInitializer = (i18next: ITranslationService): string => {
  return i18next.language;
};

export const I18N_PROVIDERS = [
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: angularLocaleInitializer,
  },
  {
    provide: MAT_DATE_LOCALE,
    deps: [I18NEXT_SERVICE],
    useFactory: angularLocaleInitializer,
  },
  {
    provide: APP_INITIALIZER,
    deps: [I18NEXT_SERVICE, HttpService],
    multi: true,
    useFactory: (
      i18next: ITranslationService,
      httpService: HttpService,
    ) => async () => {
      const i18_next_ref: I18NextLoadResult = await i18next
        .use(i18Backend)
        .use(LanguageDetector)
        .init({
          supportedLngs: [LANG.UK],
          fallbackLng: LANG.DEFAULT,
          debug: false,
          returnEmptyString: false,
          defaultNS: 'general',
          ns: ['general'],
          missingKeyNoValueFallbackToKey: true,
          saveMissing: true,
          missingKeyHandler: (
            lngs: readonly string[],
            ns: string,
            key: string,
          ) => {
            httpService
              .post('/api/localizations/missing-key', {
                lng: lngs[0],
                ns,
                key,
                url: location.href,
                browser: navigator.userAgent,
                lan_browser: navigator.language,
                lng_storage: localStorage.getItem('i18next_lng'),
              })
              .subscribe();
          },
          // parseMissingKeyHandler: () => '', пока в комменте - чтобы наглядно дебажить
          detection: LANGUAGES_DETECTOR_OPTIONS,
          interpolation: {
            format: (value: string, format: string, lng: string) =>
              value
                ? moment(value)
                    .locale(lng)
                    .format(format)
                : '',
          },
          backend: {
            loadPath: `${environment.apiUrl}/api/localizations?local={{lng}}&ns={{ns}}`,
            parse: (data: string) => JSON.parse(data).response,
          },
        });

      const module = await import(
        /* webpackExclude: /\.d\.ts$/ */
        `@/../@angular/common/locales/${i18next.language}`
      );

      if (module) registerLocaleData(module.default);

      moment.locale(i18next.language);

      return of(i18_next_ref).toPromise();
    },
  },
];
