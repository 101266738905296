import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';

import { PermissionsService } from '@services/permissions/permissions.service';

@Injectable()
export class DebtorsGuard {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
  ) {}

  public canActivate(): boolean | UrlTree {
    return (
      this.permissionsService.checkPermission({ name: 'debtors' }) ||
      this.router.parseUrl('/error/403')
    );
  }
}
