import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Constants } from '@constants/constants';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { layoutLinks } from '../layout.constants';
import { GlobalService } from '@services/global/global.service';
import { I18NextPipe } from 'angular-i18next';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { LayoutConstant } from '../layout.constants';
import { UserService } from '@services/user/user.service';
import { WorkerService } from '@services/worker/worker.service';

@Component({
  selector: 'app-layout-quick-search-dialog',
  templateUrl: './layout-quick-search-dialog.component.html',
  styleUrls: ['./layout-quick-search-dialog.component.scss'],
})
export class LayoutQuickSearchDialogComponent
  implements OnDestroy, AfterViewInit {
  public start_search = true;
  public nothing_found = false;

  public found_items = layoutLinks();
  public items = [];
  public selected_result_index: number | null = 0;

  public saver_key: string;
  public locals_items: any = [];
  public search_value = '';

  public search_control = new UntypedFormControl('');

  public search_change$ = new Subject();
  public router_sub$ = new Subscription();

  public get user_id(): string {
    return this.globalService.user._id;
  }

  public get school_id(): string {
    return this.globalService.school._id;
  }

  @ViewChild('search_input') public search_input!: ElementRef;

  @ViewChild('content_list', { read: ElementRef })
  public content_list: ElementRef<HTMLElement>;

  @ViewChild('content_item', { read: ElementRef })
  public content_item: ElementRef<HTMLElement>;

  @HostListener('document:keydown', ['$event'])
  private handleKeyboardEvent(event: KeyboardEvent): void {
    this.handleArrowKeyEvents(event);
  }

  constructor(
    private globalService: GlobalService,
    private i18next: I18NextPipe,
    private router: Router,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private workerService: WorkerService,
    private matDialogRef: MatDialogRef<LayoutQuickSearchDialogComponent>,
  ) {
    this.saver_key = 'quick_search_dialog_items';

    this.locals_items = this.getItems();

    this.search_change$
      .pipe(
        debounceTime(Constants.DEBOUNCE),
        map((event: Event) => (<HTMLInputElement>event.target).value),
        tap((value) => {
          if (!value.length) {
            this.items = [];
            this.start_search = true;
            this.nothing_found = false;
          }
        }),
        filter((value: string) => {
          this.search_value = value.trim().toLowerCase();
          return value.trim().length > 0;
        }),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.filterItems();
      });

    this.router_sub$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => this.closeMatDialog());
  }

  public ngAfterViewInit(): void {
    this.search_input.nativeElement.focus();

    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.search_change$?.unsubscribe();
  }

  public logout(): void {
    this.userService.logout().subscribe(({ success }) => {
      if (success) {
        this.workerService.closeSocket();

        this.router.navigate(['/', 'login']);
      }
    });
  }

  private handleArrowKeyEvents(event: KeyboardEvent): void {
    const key = event.key;
    let array = [];

    if (this.search_value.length > 0) {
      if (this.items.length > 0) {
        array = this.items;
      } else {
        this.nothing_found = true;
        this.start_search = false;
      }
    } else {
      array = this.locals_items.length > 0 ? this.locals_items : undefined;
    }

    this.selectKeyAction(key, array, event);
  }

  private selectKeyAction(
    key: string,
    array: any[],
    event: KeyboardEvent,
  ): void {
    const key_actions = {
      ArrowUp: () => this.moveSelection(-1, array),
      ArrowDown: () => this.moveSelection(1, array),
      Enter: () => {
        if (array?.length > 0) {
          const selected_item = array[this.selected_result_index];
          this.onResultClick(selected_item);
        }
      },
    };

    if (key in key_actions) {
      event.preventDefault();
      key_actions[key]();
    }
  }

  private closeMatDialog(): void {
    this.matDialogRef.close();
  }

  private onResultClick(item: any): void {
    this.closeMatDialog();

    item?.type === 'exit'
      ? this.logout()
      : this.router.navigate(item.router, { queryParams: item.params });

    this.locals_items = this.getItems();

    this.setItems(this.locals_items, item);
  }

  private getItems(): any {
    let value_from_storage = [];

    if (localStorage.getItem(this.saver_key)) {
      value_from_storage = JSON.parse(localStorage.getItem(this.saver_key));

      value_from_storage.forEach((element, index) => {
        element.is_active = !index ? true : false;
      });
    }

    return value_from_storage;
  }

  private setItems(get_locals: any, item): void {
    if (get_locals?.length) {
      const existing_index = get_locals.findIndex(
        (local_item) => local_item.title === item.title,
      );

      if (existing_index !== -1) {
        const existing_item = get_locals[existing_index];

        get_locals.splice(existing_index, 1);
        get_locals.unshift(existing_item);
      } else {
        get_locals.unshift(item);

        if (get_locals.length > LayoutConstant.MAX_LOCALS_LENGTH) {
          get_locals.splice(LayoutConstant.MAX_LOCALS_LENGTH);
        }
      }
    } else {
      get_locals = [item];
    }

    localStorage.setItem(this.saver_key, JSON.stringify(get_locals));
  }

  private moveSelection(step: number, value: any[]): void {
    const active_index = value.findIndex((item) => item.is_active);

    if (active_index !== -1) {
      const new_index = active_index + step;

      if (new_index >= 0 && new_index < value.length) {
        value[active_index].is_active = false;
        value[new_index].is_active = true;
        this.selected_result_index = new_index;

        this.scrollList(new_index);
      }
    }
  }

  private scrollList(new_index: number): void {
    const list_container = this.content_list.nativeElement;
    const item_height = this.content_item.nativeElement.clientHeight;
    const item_offset_top = new_index * item_height;
    const container_height = list_container.clientHeight;

    if (item_offset_top < list_container.scrollTop) {
      list_container.scrollTop = item_offset_top;
    } else if (
      item_offset_top + item_height >
      container_height + list_container.scrollTop
    ) {
      list_container.scrollTop =
        item_offset_top + item_height - container_height;
    }
  }

  private filterItems(): void {
    this.found_items.forEach((item) => {
      item.is_active = false;
    });

    this.items = this.found_items.reduce((acc, item) => {
      const transformed_title = this.i18next
        .transform(item.title)
        .toLowerCase();

      if (transformed_title.includes(this.search_value)) {
        if (!acc.some((active_item) => active_item.is_active)) {
          item.is_active = true;
        }
        acc.push(item);
      } else {
        this.nothing_found = true;
        this.start_search = false;
      }

      return acc;
    }, []);
  }
}
