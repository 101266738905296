import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class CvsResultsGuardService {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (
      route.queryParamMap.get('school') &&
      !this.permissionsService.checkPermission({
        name: 'student_cv',
        schools: [route.queryParamMap.get('school')],
      })
    ) {
      this.router.navigate(['/error/403']);
      return false;
    }

    return true;
  }
}
